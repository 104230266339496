<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Estefania Villada Chavarria                                   ###### -->
<!-- ###### @date: Junio 2024                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <section class="subHeader-content">
    <BlueHeader :itemsHeader="itemsHeader" />

    <!-- Seccion para cargar el contenido de la navegación de las rutas hijas del submenu-->
    <v-slide-y-transition mode="out-in">
      <router-view></router-view>
    </v-slide-y-transition>

  </section>
</template>
<!-- #################################################################################### -->
<!-- ###### Sección de Script                                                      ###### -->
<!-- #################################################################################### -->
<script>
import { Role } from "@/router/role.js";
import BlueHeader from "../../../../components/BlueHeader.vue";

export default {
  name: 'Structure',
  components: { BlueHeader },
  data: () => ({
    itemsHeader: [
      {
        name: 'Contratos',
        link: { name: 'modules.humanTalent.structure.contratos' },
        rol: Role.TalentoHumano_Estructura_Contratos,
      },
      {
        name: 'Colaboradores',
        link: { name: 'modules.humanTalent.structure.colaboradores' },
        rol: Role.TalentoHumano_Estructura_Colaboradores,
      },
      {
        name: 'Cargos',
        link: { name: 'modules.humanTalent.structure.cargos' },
        rol: Role.TalentoHumano_Estructura_Cargos,
      },
      {
        name: 'Tipos de Contrato',
        link: { name: 'modules.humanTalent.structure.tiposContratos' },
        rol: Role.TalentoHumano_Estructura_TiposContratos,
      }, 
      {
        name: 'Procesos',
        link: { name: 'modules.humanTalent.structure.procesos' },
        rol: Role.TalentoHumano_Estructura_Procesos,
      },
      {
        name: 'Grupos de Proceso',
        link: { name: 'modules.humanTalent.structure.grupoProceso' },
        rol: Role.TalentoHumano_Estructura_GrupoProceso,
      },
      {
        name: 'Ubicaciones',
        link: { name: 'modules.humanTalent.structure.ubicaciones' },
        rol: Role.TalentoHumano_Estructura_Ubicaciones,
      },
      {
        name: 'Niveles Jerárquicos',
        link: { name: 'modules.humanTalent.structure.nivelesJerarquicos' },
        rol: Role.TalentoHumano_Estructura_NivelesJerarquicos,
      },
      {
        name: 'Lider Grupo',
        link: { name: 'modules.humanTalent.structure.liderGrupo' },
        rol: Role.TalentoHumano_Estructura_LiderGrupo,
      },
    ]
  }),
}
</script>
<!-- #################################################################################### -->
<!-- ###### Sección de Style                                                       ###### -->
<!-- #################################################################################### -->
<style scoped>
.subHeader-content {
  width: 96%;
}
</style>